var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.to
    ? _c(
        "router-link",
        { staticClass: "govuk-back-link", attrs: { to: _vm.to } },
        [_vm._t("default")],
        2
      )
    : _c(
        "a",
        {
          staticClass: "govuk-back-link",
          attrs: { href: "javascript:void(0);" },
          on: {
            click: function($event) {
              return _vm.$emit("click")
            }
          }
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }